<template>
  <TemplateDataComp />
</template>

<script>
import TemplateDataComp from "@/layouts/TemplateDataComp.vue";
export default {
  name: "CarData",
  components: { TemplateDataComp },
};
</script>
